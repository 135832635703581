<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/register/result/index.vue
 * @Author: 张兴业
 * @Date: 2020-12-13 18:20:45
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-01-08 16:14:03
-->
<template>
  <div class="result">
    <div class="content">
      <el-image
        :src="require('@/assets/download/retail_app.png')"
        class="logo"
      />
      <div class="result_title">恭喜新晋店主！</div>
      <div class="result_subtitle">甄选品质好货·分享品位生活</div>
      <div
        v-if="isAndroid && isWxClient"
        class="start"
        @click.stop="goDownload"
      >
        <span class="start_title">开启火龙购</span>
        <el-image
          :src="require('@/assets/download/arrow.png')"
          class="start_arrow"
        ></el-image>
      </div>
      <a v-else :href="url">
        <div class="start">
          <span class="start_title">开启火龙购</span>
          <el-image
            :src="require('@/assets/download/arrow.png')"
            class="start_arrow"
          ></el-image>
        </div>
      </a>
    </div>
    <div v-if="show" class="guid_back" @click.stop="closeGuid">
      <div class="guid">
        <img src="@/assets/download/guid.png" alt="" />
      </div>
      <div class="mask_c">
        <div class="hint1">1. 点击右上角</div>
        <div class="hint2">
          {{
            !isIPhoneOrIPad
              ? "2. 点击`在浏览器中打开`"
              : "2. 点击`在Safari中打开`"
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isIPhoneOrIPad, isAndroid, isWxClient } from "@/utils/pattern";
export default {
  data() {
    return {
      url: "itms-apps://itunes.apple.com/app/6670529754",
      show: false,
    };
  },
  computed: {
    isIPhoneOrIPad() {
      return isIPhoneOrIPad();
    },
    isAndroid() {
      return isAndroid();
    },
    isWxClient() {
      return isWxClient();
    },
  },
  mounted() {
    this.resetUrl();
  },
  methods: {
    resetUrl() {
      // this.$router.push("/download/app/retail");
      if (this.isIPhoneOrIPad) {
        this.url = "itms-apps://itunes.apple.com/app/6670529754";
      }
      if (this.isAndroid) {
        this.url = "https://assets-apk.hooloogostore.com/store-retail/hlg.apk";
      }
    },
    goDownload() {
      this.show = true;
    },
    closeGuid() {
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.result {
  position: relative;
  width: 100%;
  height: 100vh;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000000;

    .logo {
      max-width: 200px;
    }

    .result_title {
      margin-top: 30px;
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
    }

    .result_subtitle {
      margin-top: 12px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }

    .start {
      margin-top: 180px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: row;
      width: 153px;
      height: 40px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 20px;
      border: 1px solid #979797;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      cursor: pointer;
      // box-sizing: border-box;

      .start_title {
        margin: auto 4px auto auto;
      }

      .start_arrow {
        margin: auto auto auto 4px;
        width: 24px;
        height: 24px;
      }
    }
  }

  .guid_back {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
  }
  .guid {
    text-align: right;
    padding: 8px 16px;
  }
  .guid img {
    width: 120px;
    height: 156px;
  }
  .mask_c {
    background: #fff;
    margin: 8px 24px auto 24px;
    border-radius: 5px;
    padding: 12px;
    font-size: 14px;
    color: #000;
    text-align: left;
  }
}
</style>
